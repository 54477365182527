import logo from './logo.svg';
import './App.css';
import ChatGpt from './ChatGpt';
import html2canvas from 'html2canvas';
import { useState } from 'react';
import goku from './assets/img/goku.png'
import { FaDownload, FaUpload } from 'react-icons/fa'; // Import a file upload icon from react-icons library
import Hero from './Hero';
import Gemini from './Gemini';
import { FiChevronDown } from 'react-icons/fi';
import gemini from './assets/img/gemini.svg'

function App() {
  const [avatar, setAvatar] = useState(goku);
  const [selectedOption, setSelectedOption] = useState('ChatGPT');

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleAvatarChange = (event) => {
    const file = event.target.files[0]; // Get the uploaded file
    const reader = new FileReader();

    reader.onload = () => {
      setAvatar(reader.result); // Set the uploaded image as the avatar
    };

    if (file) {
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };

  const handleDownload = () => {
    const element = document.getElementById('contentToDownload');

    html2canvas(element).then(function(canvas) {
      const imageData = canvas.toDataURL('image/png');
      
      const link = document.createElement('a');
      link.download = 'fake-ai.png';
      link.href = imageData;
      link.click();
    });
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-brand">Fake AI</div>
        {/* Add other navbar items/buttons here */}

        <div style={{display: 'flex', gap: '15px'}}>

          <a className="twitter-share-button"
              href="https://twitter.com/intent/post?text=Generate+Fake+AI+Screenshots&url=https%3A%2F%2Ffake-ai.imran.wiki%2F" target="_blank" rel="noreferrer">
              Share
          </a>
          <a href='/demo.mp4'>Demo</a>
        </div>
      </nav>

      <Hero />

      <div className="App">

        
        <div className='actions'>

          <div className="dropdown-container">
            {/* <label htmlFor="chatbotSelector">Select a Chatbot:</label> */}
            <select className="dropdown-select" id="chatbotSelector" value={selectedOption} onChange={handleChange}>
              <option value="ChatGPT">ChatGPT</option>
              <option value="Gemini">Gemini</option>
            </select>            
          </div>
          
          <div className="avatar-container">
            <label htmlFor="avatarInput" className="avatar-label"><FaUpload /> Upload Avatar</label>
            <input 
              type="file" 
              id="avatarInput" 
              className="avatar-input"
              accept="image/*" // Accept only image files
              onChange={handleAvatarChange} 
            />
          </div>
          
          <div>
            <button className='download-button' onClick={handleDownload}><FaDownload /> Download Image</button>
          </div>
          {/* <button className='download-button' onClick={handleDownload}>Select AI</button> */}
        </div>

        <div className='ai-container' id='contentToDownload'>
          {
            selectedOption === 'Gemini' && <Gemini avatar={avatar} />
          }
          {
            selectedOption === 'ChatGPT' && <ChatGpt avatar={avatar} />
          }
          
        </div>

      </div>
        <CoolLinks />
        <About />

        <AnimeWallapers/>

        <footer>
          <p>© {new Date().getFullYear()} Fake AI.</p>
        </footer>
    </>
    
  );
}

const CoolLinks = () => {
  return (
    <div className="cool-links-container">
      <h2 className="cool-links-title">😎 Cool Links</h2>
      <ul className="cool-links-list">
        <li><a href='https://promptoverflow.co/?ref=Fakeai'>👨🏻‍💻 AI Jobs Board</a></li>
        <li><a href='https://spiff.store/?ref=Fakeai'>📒 AI Tools Directory</a></li>
        <li><a href='https://bloby.imran.wiki/?ref=Fakeai'>🔵 Generate random SVG blobs with Bloby</a></li>
        <li><a href='https://crazygames.quest/?ref=Fakeai'>🕹 Play Browser Games on CrazyGames</a></li>
        <li><a href='https://www.youtube.com/watch?v=WYzFzZg4YZI&ref=Fakeai'>📚 Learn to Build a Chat with PDF app</a></li>
        <li><a href='https://simpletools.in/?ref=Fakeai'>🛠 Everyday Tools for Developers</a></li>
        <li><a href='https://ehthing.gumroad.com/l/TweetTailor/?ref=Fakeai'>🆇 Get Viral Tweets for Twitter Growth</a></li>
      </ul>
    </div>
  )
}

const AnimeWallapers = () => {
  return (
        <div className="cool-links-container">
          <h3 className="cool-links-title">Anime Wallapers</h3>
          <ul className="cool-links-list">
            <li><a href='https://anime-pfp.com/pfp/gojo'><img width="20px" src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhYJtsjpkykMdmFMmdsoUFaBkhloNTF6KGdxymF4993ImWrKW93RKtLVfaHMrkYuJChaG5QLXQGGhvdjKjwbbafPzuERPKd6GfbTl9eyz8NHH0esbeoF2c9qHVjR4kY1Z1pkh0qfehErk8FRCzo01XhzLIC-_-jylkB7rrl7VPrTL_fRGGtocHa2t-A6Zur/s128/thumb.png" alt='gojo pfp'/> Gojo Satoru</a></li>
            <li><a href='https://anime-pfp.com/pfp/sukuna'><img width="20px" src="https://cdn3.emoji.gg/emojis/52029-sukuna-gambare-mock.png" alt='Sukuna pfp'/> Sukuna</a></li>
            <li><a href='https://anime-pfp.com/pfp/goku'><img width="20px" src="https://files.softicons.com/download/culture-icons/popular-anime-icons-by-iconspedia/png/256x256/Dragonball-Goku.png" alt='Goku pfp'/> Goku</a></li>
            <li><a href='https://anime-pfp.com/pfp/luffy'><img width="20px" src="https://imgcdn.sigstick.com/X9Gfyd3KFy1ffDp3o4aV/cover.thumb256.png" alt='Luffy pfp'/> Luffy Gear 5</a></li>
            <li><a href='https://anime-pfp.com/pfp/nezuko'><img width="20px" src="https://lh3.googleusercontent.com/proxy/uerq94WXnC7lIgAy4m63WVRyBAQbRTqYLHFrb7FQTo00Bbc_GIq9lbl8Iy2YCD9wW-JGVuFVVq_Tdph-3sJ-b-Y3eO6mxfE1klnVYA" alt='Nezuko pfp'/> Nezuko</a></li>
          </ul>
        </div>
  )
}

const About = () => {
  return (
    <div className="cool-links-container">
      <h2 className="cool-links-title">🙍🏽‍♂️ About</h2>
      <p>Hello, My name is Imran Khan. I build cool projects for fun.</p>
      <ul className="cool-links-list">
        <li><a href='https://imran.wiki/?ref=Fakeai'>👋 Learn more about me</a></li>
        <li><a href='https://twitter.com/EhThing?ref=Fakeai'>🆇 Follow my journey on Twitter</a></li>
        <li><a href='mailto:contact@imran.wiki'>✉️ Business Inquiries</a></li>
      </ul>
    </div>
  )
}



export default App;
